
import * as actionTypes from '../constants/ActionType'
import {Map, fromJS} from "immutable";

const baseInit = fromJS({
  state: 4,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  user: null,
  needPull: false
});
const ListInit = Map({
  state: 4,
  param: null,
  lastFetched: null,
  isLoading: false,
  error: null,
  data: null,
  list: null,
  totalPage: 1,
  totalNumber: 0,
  currentPage: 1,
  countPerPage: 10,
  cache: null,
  needPull: false
});

export const windList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WIND_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WIND_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WIND_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WIND_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WIND_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const windHistoryList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WIND_HISTORY_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WIND_HISTORY_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WIND_HISTORY_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WIND_HISTORY_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WIND_HISTORY_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const windForecastList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WIND_DATE_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WIND_DATE_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WIND_DATE_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WIND_DATE_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WIND_DATE_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const airportVerticalList = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_AIRPORT_VERTICAL_LIST_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_AIRPORT_VERTICAL_LIST_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_AIRPORT_VERTICAL_LIST_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_AIRPORT_VERTICAL_LIST_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_AIRPORT_VERTICAL_LIST_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const aqsToken = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_TOKEN_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_TOKEN_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_TOKEN_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_TOKEN_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_TOKEN_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const weatherInfo = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WEATHER_INFO_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WEATHER_INFO_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WEATHER_INFO_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WEATHER_INFO_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WEATHER_INFO_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const warningInfo = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WARNING_INFO_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WARNING_INFO_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WARNING_INFO_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WARNING_INFO_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WARNING_INFO_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const weatherTip = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WEATHER_TIP_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WEATHER_TIP_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WEATHER_TIP_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WEATHER_TIP_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WEATHER_TIP_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const weatherOneWord = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_ONE_WORD_INFO_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_ONE_WORD_INFO_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_ONE_WORD_INFO_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_ONE_WORD_INFO_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_ONE_WORD_INFO_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}

export const weatherIndex = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WEATHER_INDEX_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WEATHER_INDEX_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WEATHER_INDEX_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WEATHER_INDEX_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WEATHER_INDEX_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const weatherAirport = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WEATHER_AIRPORT_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WEATHER_AIRPORT_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WEATHER_AIRPORT_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WEATHER_AIRPORT_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WEATHER_AIRPORT_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const weatherWave = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_WEATHER_WAVE_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_WEATHER_WAVE_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_WEATHER_WAVE_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_WEATHER_WAVE_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_WEATHER_WAVE_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const angleView = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_ANGLE_VIEW_IMAGE_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_ANGLE_VIEW_IMAGE_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_ANGLE_VIEW_IMAGE_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_ANGLE_VIEW_IMAGE_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_ANGLE_VIEW_IMAGE_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}


export const topView = (state = ListInit, action) => {
  switch (action.type) {
    case actionTypes.GET_TOP_VIEW_IMAGE_REQUEST:
      return state.merge({
        state: 0,
        param: null,
        lastFetched: null,
        isLoading: true,
        error: null,
        data: null
      });
    case actionTypes.GET_TOP_VIEW_IMAGE_SUCCESS:
      if (state.get('isLoading')) {
        return state.merge({
          state: 1,
          param: action.param,
          data: action.response,
          lastFetched: action.lastFetched,
          isLoading: false,
          // lists: { ...state.toJS().lists, ...(action.response && action.response.entities.lists) },
          error: null
        });
      }
      return state;
    case actionTypes.GET_TOP_VIEW_IMAGE_FAILURE:
      if (state.get('isLoading')) {
        return state.merge({
          state: 2,
          isLoading: false,
          error: action.error,
          errorCode: action.errorCode
        });
      }
      return state;
    case actionTypes.GET_TOP_VIEW_IMAGE_UPDATE:
      const param = action.param;
      /* list:action.param.list,
       totalPage:action.param.totalPage,
       totalNumber:action.param.totalNumber,
       currentPage:action.param.currentPage */
      return state.merge({
        state: 4,
        ...param
      });
    case actionTypes.GET_TOP_VIEW_IMAGE_RESET:
      return ListInit.merge({
        state: 4,
        param: action.param
      });
    default:
      return state;
  }
}
