import Box from "@mui/material/Box/Box";
import React, {useEffect, useRef} from "react";

// import * as Cesium from 'cesium';
import { Ion, Viewer, createWorldTerrain, createOsmBuildings, Cartesian3, Math } from "cesium"
import "cesium/Build/Cesium/Widgets/widgets.css";


window.CESIUM_BASE_URL = '/static/cesium/';
Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIwZGIzM2NkNS0wM2E5LTQ3M2UtYmMzZi03NmJjMDMwZDc1YWUiLCJpZCI6OTE2MDMsImlhdCI6MTY1MTEzMDMxOX0.OzUCEQMouuKt5fTTtquqc7c2t3z2L-ld2QqFsMxvxaA'



const CesiumMap = (props, ref ) => {
  const { center, autoRotate = false, modeChange ,speedNum=0.001} = props;

  const viewer = ref;

  const currentMode = useRef(3)
  const lastRotate = useRef(false);
  const speedRef = useRef(0.001)

  const routeFun = (num) =>()=> {
    viewer.current && viewer.current.camera.rotateRight( speedRef.current);
  }

  const routeFun2 = (num) => ()=>{

    viewer.current && viewer.current.camera.rotateRight(- speedRef.current);
  }

  useEffect(() => {
    viewer.current= new Viewer('cesiumContainer', {
      // terrainProvider: Cesium.createWorldTerrain(),
      // baseLayer: Cesium.ImageryLayer.fromProviderAsync(
      //   Cesium.ArcGisMapServerImageryProvider.fromBasemapType(
      //     Cesium.ArcGisBaseMapType.SATELLITE
      //     // other supported styles include:
      //     // Cesium.ArcGisMapServerImageryProvider.HILLSHADE
      //     // Cesium.ArcGisMapServerImageryProvider.OCEANS
      //   )
      // ),
      // geocoder:false,//位置查找工具
      // homeButton:false,//复位按钮
      // sceneModePicker:false,//模式切换
      // baseLayerPicker:false,//图层选择
      // navigationHelpButton:false,//帮助按钮
      animation:false,//速度控制
      timeline:false,//时间轴
      shadows: false,
      // fullscreenButton:false,//全屏
    });
    // viewer.current.scene.globe.rotationSpeed = 0.003;
 
    // 自定义Cesium Logo
    const cesiumLogo = viewer.current.cesiumWidget.container.querySelector('.cesium-logo');
    if (cesiumLogo) {
      cesiumLogo.style.display = 'none'; // 隐藏Cesium Logo
    }
 
    // 自定义版权信息
    const credits = viewer.current.cesiumWidget.container.querySelector('.cesium-viewer-cesiumWidgetContainer');
    console.log(22222,viewer.current.cesiumWidget.container)
    console.log(credits)
    if (credits) {
      credits.innerHTML = '自定义版权信息'; // 替换版权信息
    }
    viewer.current.camera.flyTo({
      destination : Cartesian3.fromDegrees(...(center || [78, 22]), 1000 * 8 * 1000),
      // destination: Cartesian3.fromDegrees(-117.16, 32.71, 15000.0),
      // orientation : {
      //   heading : Cesium.Math.toRadians(0.0),
      //   pitch : Cesium.Math.toRadians(-45.0),
      // }
      // orientation: {
      //   heading: Cesium.Math.toRadians(0.0),
      //   pitch: Cesium.Math.toRadians(-90.0),
      //   roll: 0.0,
      // },
    });
  


    // 监听模式切换事件
    viewer.current.scene.postRender.addEventListener(()=>{
      currentMode.current = viewer.current.scene.mode;
      // console.log(currentMode.current)
      // if( currentMode.current!=3){
        if(modeChange) {
          modeChange(currentMode.current)
        }
      // }
      // console.log('Current mode:', currentMode.current);
    });
 

    

  }, []);


  useEffect(()=>{
    speedRef.current = speedNum
  },[speedNum]) 

  useEffect(() => {
    if(lastRotate.current !== autoRotate){
      
        if(!autoRotate) {
          console.log(23344)
          console.log(autoRotate)

        viewer.current.clock.onTick.addEventListener(routeFun(speedNum));
        } else {
          console.log(444444)
        

         viewer.current.clock.onTick.addEventListener(routeFun2(speedNum));
          // viewer.current.clock.onTick.removeEventListener(routeFun);
          // viewer.current.camera.cancelFlight();
        }
  

      lastRotate.current = autoRotate;

    }
  }, [autoRotate]);

  useEffect(() => {
    return () => {
      if (viewer.current) {
        viewer.current.destroy();
      }
    };
  }, []);

  return (
    <Box sx={{width: '100%',minWidth:'calc(100vw - 300px)', height: '100%',position:'relative'}} id="cesiumContainer" > </Box>
  )
}

export default React.forwardRef(CesiumMap)
