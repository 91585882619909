import React from 'react'
import {Routes, Route, useLocation, Navigate, Outlet, BrowserRouter} from "react-router-dom";
import NotFoundRouteView from "../views/NotFoundRouteView";
import HomeView from "../views/HomeView";
import GRACEView from "../views/GRACEView";
import EWHDeepView from "../views/EWHDeepView";
import TreeView from "../views/TreeView";
import HeightView from "../views/HeightView";
import Menu from "../components/Menu";
import DEMView from "../views/DEMMView/DEMView";
import ClassificationView from "../views/ClassificationView";

import CESEWHView from '../views/CESEWHView'
import LoginView from '../views/LoginView'
import CESHeightView from '../views/CESHeightView'
import CESTreeView from '../views/CESTreeView'
import CESDEMMView from '../views/CESDEMMView'
import CESTerrestrialView from '../views/CESTeerestrialView'
import CESTerrestrialMonthView from '../views/CESTeerestrialMonthView'
import CESGlobleTestView from '../views/CESGlobleTestView'
import CESGraceNewView from '../views/CESGraceNewView'


import CssBaseline from "@mui/material/CssBaseline";

import Echart from "../views/EchartView"
import WaterLevelView from "../views/WaterLevelView";

import GFCHView from "../views/GFCHView";
import CESGFCHView from "../views/CESGFCHView";
import CESBiomassView from "../views/CESBiomassView";
import FloodView from "../views/FloodView";
import FireView from "../views/FireView";

export default (props) => {
  return (

    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="/">
          <Route index element={<Navigate to={'/login'} />} />
          <Route path={`login`} element={<LoginView />} />
          <Route path={`echart`} element={<Echart />} />
          <Route path="/console/" element={<Menu />}>
            <Route index element={<Navigate to={'grace'} />} />
            <Route path={`ewh`} element={<HomeView />} />
            {/*<Route path={`grace`} element={<GRACEView />} />*/}
            <Route path={`grace`} element={<CESGraceNewView />} />
            <Route path={`ewhDeep`} element={<EWHDeepView />} />
            {/*<Route path={`ewhDeep`} element={<CESGraceNewView />} />*/}
            {/*<Route path={`igbp`} element={<TreeView />} />*/}
            <Route path={`igbp`} element={<CESTreeView />} />
            <Route path={`dem`} element={<DEMView />} />
            <Route path={`srtm`} element={<HeightView />} />
            <Route path={`water`} element={<WaterLevelView />} />
            <Route path={`classification`} element={<ClassificationView />} />
            <Route path={`gfch`} element={<CESGFCHView />} />
            <Route path={`bionmass`} element={<CESBiomassView />} />
            <Route path={`terrestrial/daily`} element={<CESTerrestrialView />} />
            <Route path={`terrestrial/monthly`} element={<CESTerrestrialMonthView />} />
            <Route path={`flood`} element={<FloodView />} />
            <Route path={`fire`} element={<FireView />} />
            {/*<Route path={`test`} element={<CESGlobleTestView />} />*/}
            {/*<Route path={`test`} element={<CESGraceNewView />} />*/}
          </Route>
        </Route>
        <Route path="/ces/" element={<Menu />}>
          <Route index element={<Navigate to={'ewh'} />} />
          <Route path={`ewh`} element={<CESEWHView />} />
          <Route path={`igbp`} element={<CESTreeView />} />
          <Route path={`dem`} element={<CESDEMMView />} />
          <Route path={`srtm`} element={<CESHeightView />} />
        </Route>
        <Route path={`nopage`} element={<NotFoundRouteView />} />
        <Route path="*" element={<NotFoundRouteView />} />
      </Routes>
    </BrowserRouter>
  )
}

