import React, {} from 'react'

import { colorList } from './colors'

import './colorBar.scss'

const backColor = [];

colorList.map((item, index) => {
  backColor.push(`rgb(${item.join(',')})`)
});

const colorText = [];

for(let i =3; i >= -3; i--) {
  colorText.push(i * 100);
}

const bgColor = `linear-gradient(${backColor.join(',')})`;

export default (props) => {
  return (
    <div className="color-bar-root">
      <div className="color-bar" style={{background: bgColor}}>
      </div>
      <div className="color-bar-text">
        {
          colorText.map((item, index) => {
            return (
              <div className="color-bar-text-item" key={`bar-text-${index}`}>{item}</div>
            )
          })
        }
      </div>
    </div>
  )
}
