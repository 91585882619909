export const colorList = [
  [254, 223, 238],
  [245, 165, 250],
  [250, 97, 188],
  [250, 30, 66],
  [250, 95, 75],
  [250, 155, 55],
  [254, 225, 68],
  [254, 255, 68],
  [195, 250, 75],
  [120, 255, 72],
  [60, 255, 55],
  [65, 255, 195],
  [60, 255, 255],
  [70, 220, 245],
  [70, 190, 250],
  [0, 160, 255],
  [0, 130, 245],
  [66, 0, 178],
  [60, 0, 65]
];


export const getColor = (value) => {
  if(value >= 266) {
    return colorList[0];
  } else if(value >= 233) {
    return colorList[1];
  } else if(value >= 200) {
    return colorList[2];
  } else if(value >= 166) {
    return colorList[3];
  } else if(value >= 133) {
    return colorList[4];
  } else if(value >= 100) {
    return colorList[5];
  } else if(value >= 66) {
    return colorList[6];
  } else if(value >= 33) {
    return colorList[7];
  } else if(value >= 0) {
    return colorList[8];
  } else if(value >= -33) {
    return colorList[9];
  } else if(value >= -66) {
    return colorList[10];
  } else if(value >= -100) {
    return colorList[11];
  } else if(value >= -133) {
    return colorList[12];
  } else if(value >= -166) {
    return colorList[13];
  } else if(value >= -200) {
    return colorList[14];
  } else if(value >= -233) {
    return colorList[15];
  } else if(value >= -266) {
    return colorList[16];
  } else if(value >= -300) {
    return colorList[17];
  } else {
    return colorList[18];
  }
}


export const colorList2 = [
  [250, 30, 66],
  [250, 95, 75],
  [250, 155, 55],
  [254, 225, 68],
  [254, 255, 68],
  [195, 250, 75],
  [120, 255, 72],
  [60, 255, 55],
  [65, 255, 195],
  [60, 255, 255],
  [70, 220, 245],
  [70, 190, 250],
  [0, 160, 255],
  [0, 130, 245],
];

export const getColor2 = (value) => {
  const index = Math.ceil((51 - value) / 5);
  return colorList2[index < 0 ? 0 : index];
}