/**
 * ActionType
 * const action list
 * Created by luzq on 16/5/9.
 */
/*
* 更新 加密密钥
* */
export const UPDATE_CODE = 'UPDATE_CODE';

/* error msg */
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const RESET_ERROR = 'RESET_ERROR';
/* size*/
export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE';
/* modal */
export const SET_MODAL = 'SET_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const WAITING_MODAL = 'WAITING_MODAL';
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';
export const SET_WAITING = 'SET_WAITING';


/*
* getWindList
* */
export const GET_WIND_LIST_LOAD = 'GET_WIND_LIST_LOAD';
export const GET_WIND_LIST_REQUEST = 'GET_WIND_LIST_REQUEST';
export const GET_WIND_LIST_SUCCESS = 'GET_WIND_LIST_SUCCESS';
export const GET_WIND_LIST_FAILURE = 'GET_WIND_LIST_FAILURE';
export const GET_WIND_LIST_UPDATE = 'GET_WIND_LIST_UPDATE';
export const GET_WIND_LIST_RESET = 'GET_WIND_LIST_RESET';

/*
* getAirPort
* */
export const GET_AIRPORT_VERTICAL_LIST_LOAD = 'GET_AIRPORT_VERTICAL_LIST_LOAD';
export const GET_AIRPORT_VERTICAL_LIST_REQUEST = 'GET_AIRPORT_VERTICAL_LIST_REQUEST';
export const GET_AIRPORT_VERTICAL_LIST_SUCCESS = 'GET_AIRPORT_VERTICAL_LIST_SUCCESS';
export const GET_AIRPORT_VERTICAL_LIST_FAILURE = 'GET_AIRPORT_VERTICAL_LIST_FAILURE';
export const GET_AIRPORT_VERTICAL_LIST_UPDATE = 'GET_AIRPORT_VERTICAL_LIST_UPDATE';
export const GET_AIRPORT_VERTICAL_LIST_RESET = 'GET_AIRPORT_VERTICAL_LIST_RESET';

/*
* getWindHistoryList
* */
export const GET_WIND_HISTORY_LIST_LOAD = 'GET_WIND_HISTORY_LIST_LOAD';
export const GET_WIND_HISTORY_LIST_REQUEST = 'GET_WIND_HISTORY_LIST_REQUEST';
export const GET_WIND_HISTORY_LIST_SUCCESS = 'GET_WIND_HISTORY_LIST_SUCCESS';
export const GET_WIND_HISTORY_LIST_FAILURE = 'GET_WIND_HISTORY_LIST_FAILURE';
export const GET_WIND_HISTORY_LIST_UPDATE = 'GET_WIND_HISTORY_LIST_UPDATE';
export const GET_WIND_HISTORY_LIST_RESET = 'GET_WIND_HISTORY_LIST_RESET';

/*
* getWindDateList
* */
export const GET_WIND_DATE_LIST_LOAD = 'GET_WIND_DATE_LIST_LOAD';
export const GET_WIND_DATE_LIST_REQUEST = 'GET_WIND_DATE_LIST_REQUEST';
export const GET_WIND_DATE_LIST_SUCCESS = 'GET_WIND_DATE_LIST_SUCCESS';
export const GET_WIND_DATE_LIST_FAILURE = 'GET_WIND_DATE_LIST_FAILURE';
export const GET_WIND_DATE_LIST_UPDATE = 'GET_WIND_DATE_LIST_UPDATE';
export const GET_WIND_DATE_LIST_RESET = 'GET_WIND_DATE_LIST_RESET';


/*
* getToken
* */
export const GET_TOKEN_LOAD = 'GET_TOKEN_LOAD';
export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';
export const GET_TOKEN_UPDATE = 'GET_TOKEN_UPDATE';
export const GET_TOKEN_RESET = 'GET_TOKEN_RESET';


/*
* 获取天气信息
* */
export const GET_WEATHER_INFO_LOAD = 'GET_WEATHER_INFO_LOAD';
export const GET_WEATHER_INFO_REQUEST = 'GET_WEATHER_INFO_REQUEST';
export const GET_WEATHER_INFO_SUCCESS = 'GET_WEATHER_INFO_SUCCESS';
export const GET_WEATHER_INFO_FAILURE = 'GET_WEATHER_INFO_FAILURE';
export const GET_WEATHER_INFO_UPDATE = 'GET_WEATHER_INFO_UPDATE';
export const GET_WEATHER_INFO_RESET = 'GET_WEATHER_INFO_RESET';


/*
* 获取预警信息
* */
export const GET_WARNING_INFO_LOAD = 'GET_WARNING_INFO_LOAD';
export const GET_WARNING_INFO_REQUEST = 'GET_WARNING_INFO_REQUEST';
export const GET_WARNING_INFO_SUCCESS = 'GET_WARNING_INFO_SUCCESS';
export const GET_WARNING_INFO_FAILURE = 'GET_WARNING_INFO_FAILURE';
export const GET_WARNING_INFO_UPDATE = 'GET_WARNING_INFO_UPDATE';
export const GET_WARNING_INFO_RESET = 'GET_WARNING_INFO_RESET';


/*
* 获取一句话提示
* */
export const GET_ONE_WORD_INFO_LOAD = 'GET_ONE_WORD_INFO_LOAD';
export const GET_ONE_WORD_INFO_REQUEST = 'GET_ONE_WORD_INFO_REQUEST';
export const GET_ONE_WORD_INFO_SUCCESS = 'GET_ONE_WORD_INFO_SUCCESS';
export const GET_ONE_WORD_INFO_FAILURE = 'GET_ONE_WORD_INFO_FAILURE';
export const GET_ONE_WORD_INFO_UPDATE = 'GET_ONE_WORD_INFO_UPDATE';
export const GET_ONE_WORD_INFO_RESET = 'GET_ONE_WORD_INFO_RESET';


/*
* 获取天气指数
* */
export const GET_WEATHER_INDEX_LOAD = 'GET_WEATHER_INDEX_LOAD';
export const GET_WEATHER_INDEX_REQUEST = 'GET_WEATHER_INDEX_REQUEST';
export const GET_WEATHER_INDEX_SUCCESS = 'GET_WEATHER_INDEX_SUCCESS';
export const GET_WEATHER_INDEX_FAILURE = 'GET_WEATHER_INDEX_FAILURE';
export const GET_WEATHER_INDEX_UPDATE = 'GET_WEATHER_INDEX_UPDATE';
export const GET_WEATHER_INDEX_RESET = 'GET_WEATHER_INDEX_RESET';

/*
* 获取天气提示
* */
export const GET_WEATHER_TIP_LOAD = 'GET_WEATHER_TIP_LOAD';
export const GET_WEATHER_TIP_REQUEST = 'GET_WEATHER_TIP_REQUEST';
export const GET_WEATHER_TIP_SUCCESS = 'GET_WEATHER_TIP_SUCCESS';
export const GET_WEATHER_TIP_FAILURE = 'GET_WEATHER_TIP_FAILURE';
export const GET_WEATHER_TIP_UPDATE = 'GET_WEATHER_TIP_UPDATE';
export const GET_WEATHER_TIP_RESET = 'GET_WEATHER_TIP_RESET';


/*
* 获取天气提示
* */
export const GET_WEATHER_WAVE_LOAD = 'GET_WEATHER_WAVE_LOAD';
export const GET_WEATHER_WAVE_REQUEST = 'GET_WEATHER_WAVE_REQUEST';
export const GET_WEATHER_WAVE_SUCCESS = 'GET_WEATHER_WAVE_SUCCESS';
export const GET_WEATHER_WAVE_FAILURE = 'GET_WEATHER_WAVE_FAILURE';
export const GET_WEATHER_WAVE_UPDATE = 'GET_WEATHER_WAVE_UPDATE';
export const GET_WEATHER_WAVE_RESET = 'GET_WEATHER_WAVE_RESET';


/*
* 获取航空报文
* */
export const GET_WEATHER_AIRPORT_LOAD = 'GET_WEATHER_AIRPORT_LOAD';
export const GET_WEATHER_AIRPORT_REQUEST = 'GET_WEATHER_AIRPORT_REQUEST';
export const GET_WEATHER_AIRPORT_SUCCESS = 'GET_WEATHER_AIRPORT_SUCCESS';
export const GET_WEATHER_AIRPORT_FAILURE = 'GET_WEATHER_AIRPORT_FAILURE';
export const GET_WEATHER_AIRPORT_UPDATE = 'GET_WEATHER_AIRPORT_UPDATE';
export const GET_WEATHER_AIRPORT_RESET = 'GET_WEATHER_AIRPORT_RESET';


/*
* 获取gif
* */
export const GET_ANGLE_VIEW_IMAGE_LOAD = 'GET_ANGLE_VIEW_IMAGE_LOAD';
export const GET_ANGLE_VIEW_IMAGE_REQUEST = 'GET_ANGLE_VIEW_IMAGE_REQUEST';
export const GET_ANGLE_VIEW_IMAGE_SUCCESS = 'GET_ANGLE_VIEW_IMAGE_SUCCESS';
export const GET_ANGLE_VIEW_IMAGE_FAILURE = 'GET_ANGLE_VIEW_IMAGE_FAILURE';
export const GET_ANGLE_VIEW_IMAGE_UPDATE = 'GET_ANGLE_VIEW_IMAGE_UPDATE';
export const GET_ANGLE_VIEW_IMAGE_RESET = 'GET_ANGLE_VIEW_IMAGE_RESET';

/*
* 获取png
* */
export const GET_TOP_VIEW_IMAGE_LOAD = 'GET_TOP_VIEW_IMAGE_LOAD';
export const GET_TOP_VIEW_IMAGE_REQUEST = 'GET_TOP_VIEW_IMAGE_REQUEST';
export const GET_TOP_VIEW_IMAGE_SUCCESS = 'GET_TOP_VIEW_IMAGE_SUCCESS';
export const GET_TOP_VIEW_IMAGE_FAILURE = 'GET_TOP_VIEW_IMAGE_FAILURE';
export const GET_TOP_VIEW_IMAGE_UPDATE = 'GET_TOP_VIEW_IMAGE_UPDATE';
export const GET_TOP_VIEW_IMAGE_RESET = 'GET_TOP_VIEW_IMAGE_RESET';
