/**
 * Created by luzq on 16/5/10.
 */
import * as ActionTypes from '../constants/ActionType'
import { combineReducers } from 'redux'
// import { connectRouter } from 'connected-react-router'
import { Map } from 'immutable'
import { reducer as reduceForm } from 'redux-form'

import * as Base from './Base'
import * as Server from './Server'

const errorState = Map({
  flag: false,
  message: null,
  errorCode: 0,
  title: null,
  confirm: null,
  cancel: null
})
function error(state = errorState, action) {
  const { type, error } = action;
  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    if (!state.get('flag')) {
      return state.merge({
        flag: action.flag,
        message: action.message,
        errorCode: action.errorCode,
        title: action.title || null,
        confirm: action.confirm || null,
        cancel: action.cancel || null,
        onConfirm: action.onConfirm || null,
        onClose: action.onClose || null,
        shadow: action.shadow || null
      })
    } else {
      return state;
    }
  } else if (type === ActionTypes.RESET_ERROR) {
    return errorState;
  } else if (error) {
    if (!state.get('flag')) {
      return state.merge({
        flag: false,
        message: action.error.message,
        errorCode: action.error.errorCode
      })
    }
  }
  return state
}
function createReducer(asyncReducers, history) {
  const reducers = {
    // router: connectRouter(history),
    ...asyncReducers,
    ...Base,
    ...Server,
    // ...School,
    form: reduceForm,
    error
  };
  if (asyncReducers) {
    reducers.asyncReducers = asyncReducers;
  }
  return combineReducers(reducers);
}
// Only combine reducers needed for initial render, others will be
// added async
export default createReducer;
export function injectAsyncReducer(store, name, asyncReducer) {
  if (store && name && asyncReducer && !store.asyncReducers[name]) {
    store.asyncReducers[name] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  }
}
