// 预测变化趋势 只有一个分析源的

// 需要的参数：
//changeEchart 改变changeEchart重绘echart
// title(echarts标题：如'北京市2021年1月-7月最高温预报')
// timeArr (typt: array,如：['2021/1/1',2021/1/2','2021/1/3'.....'2021/7/31]）
// temperatureArr （type:array, 时间所对应的每天的最高/最低/平均温度。 如[30,24,24,.......,26]


import React, {useEffect, useState, useRef} from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles';
import Tooltip, {tooltipClasses } from '@mui/material/Tooltip'

import * as echarts from 'echarts/core';
import {LineChart} from 'echarts/charts'
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TooltipComponent,
  TitleComponent,
  GridComponent,
  ToolboxComponent,
  MarkPointComponent,
  MarkLineComponent,
  DataZoomComponent
}from 'echarts/components'
import { ContactSupportOutlined } from '@mui/icons-material';

echarts.use([
  TooltipComponent,
  TitleComponent,
  GridComponent,
  ToolboxComponent,
  MarkPointComponent,
  MarkLineComponent,
  DataZoomComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
])

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      color: '#5797C1',
      fontSize: 7,
      position:'absolute',
      top:-20,
      width:'70px',
      right:-38
    },
  }));

export  default(props) =>{

  const myChart = useRef()
  const echartRef = useRef()
  const title = useRef('')
  const timeArr = useRef()
  const temperatureArr = useRef()



  
  useEffect(()=>{
    let url = window.location.search; //获取url中"?"符后的字串
    let theRequest = new Object();
    if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for(var i = 0; i < strs.length; i ++) {
            // theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
            theRequest[strs[i].split("=")[0]]=strs[i].split("=")[1];

        }
    }
    title.current = decodeURI(theRequest['title'])
    let dataArr = theRequest['dataStr'].split('/')
    let timeArr = theRequest['timeStr'].split('/')
    
    // console.log(dataArr)
    // console.log(timeArr)


    let base = +new Date(2020, 12, 31);
    let oneDay = 24 * 3600 * 1000;
    let date = [];
    let data = [Math.random() * 20];

    if(!title.current){
      title.current = '北京市2021年1月-7月最高温预报'
      for (let i = 1; i < 210; i++) {
      var now = new Date((base += oneDay));
        date.push([now.getFullYear(), now.getMonth(), now.getDate()].join('/'));
        data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
      }
    }else{
      date = timeArr
      data = dataArr
    }
    let option = {
      tooltip: {
        trigger: 'axis',
      },
      grid:{
        left:50,
        right:50,
        top:50,
        bottom:40,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name:'Time',
        data: date,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'EWH',
          type: 'line',
          data: data,
        },
      ]
    };
    if( echartRef.current){
      let  getItem = echarts.init(echartRef.current);
    
      // 绘制图表，option设置图表格式及源数据
      myChart.current = getItem

      myChart.current.clear()
      myChart.current.setOption(option);
      
    }

  },[])

  // window.onresize = function() {
  //   myChart.current.resize()
  // }

  return(
    <Box sx={{ width: "100vw", height: "100vh",}}>
      <Box ref={echartRef} sx={{ width: "100%", height: "100% "}}></Box>
    </Box>
  )
}


