import React from 'react'
import Box from "@mui/material/Box/Box";

export default (props) => {
  const { labels = [], colors = [], unit } = props;

  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        zIndex: 30,
        width: 370,
        color: 'rgba(255, 255, 255, 255)',
        height: '26px',
        borderRadius: '10px',
        textShadow:'#000 0px 0px 5px',
        right: '10px',
        bottom: '60px',
        fontSize: '14px',
        textAlign: 'center',
        backgroundImage: `linear-gradient(to right, ${(unit ?
          [colors[0], colors[0], colors[0], ...colors, colors[colors.length - 1]] :
          [colors[0], colors[0], ...colors, colors[colors.length - 1]]
        ).join(',')})`,
      }}
    >
      {
        (unit ? [unit, ...labels] : [...labels]).map((item, index) => {
          return (
            <Box
              sx={{flex: 1}}
              key={'legend-' + index}>
              {
                item
              }
            </Box>
          )
        })
      }
    </Box>
  )
}
