import React, {useRef, useEffect, useState} from "react";
import 'isomorphic-fetch'
import { makeStyles } from '@mui/styles';

// import dataArr from './data.json'
// import dataArr from './static/json/2010-08-01.json'
import { getColor, colorList } from '../../components/colors'

import CesiumMap from "../../components/CesiumMap";
import {createImg, getImage} from "../../components/imgFactory";
import * as Cesium from "cesium";

import ColorBar from '../../components/ColorBar'
import VerticalLegend from "../../components/VerticalLegend";
import MapCtrol from "../../components/MapCtrol";
import config from '../../config'
import Box from "@mui/material/Box/Box";
import Helmet from "react-helmet";
import { positions } from "@mui/system";

const useStyles = makeStyles({
  root: {
    fontFamily: "微软雅黑",
    height: '100vh',
    width: '100%',
  },
});

const dateList1 = ['Before Avalanche','After Avalanche']

const dateList = [
];

const labelList = [
];

for(let i = 1; i < 20; i ++) {
  dateList.push((2000 + i) + '-01-01');
  labelList.push(2000 + i);
}

const basePoint = [
  34.515645,
  68.390467
]

const colorLists = [...colorList].reverse().map((item) => {
  return `rgb(${item.join()})`
});

let t = null;

function App() {
  const [map, setMap] = useState();
  const [mapLoading, setMapLoading] = useState();
  const mapRef = useRef();
  const cesMap = useRef();
  const currentMode =useRef('')
  const imageMaterial = useRef();

  const imgLayer = useRef();

  const overlayRef = useRef();

  const [currentDateIndex, setCurrentDate] = useState(0);
  const lastCurrentDateIndex = useRef(0);

  const dataList = useRef({});

  const classes = useStyles();

  const currentDate = dateList[currentDateIndex] || dateList[0];
  const [isLoading, setIsLoading] = useState(false);
  const [changePic,setChangePic] = useState(0)

  const picUrl= useRef('/demImage/FilteredDSM_reg_filled1.png')
  const staticImagePrimitive = useRef(null)

  // const nextTime = () => {
  //   if(map) return;
  //   setMap({});
  //   try{
  //     const itMap = new window.Microsoft.Maps.Map(mapRef.current, {
  //       center: new window.Microsoft.Maps.Location(30.35, 79.8),
  //       zoom: 12,
  //       mapTypeId: window.Microsoft.Maps.MapTypeId.aerial,//设置地图类型
  //       labelOverlay: window.Microsoft.Maps.LabelOverlay.hidden//地图上的所有标签（如街道名、城市名等）都被设置为隐藏状态
  //     });
  //     setMap(itMap);
  //     setCurrentDate(0);
  //     overlayRef.current = new window.Microsoft.Maps.GroundOverlay({
  //       // bounds: window.Microsoft.Maps.LocationRect.fromEdges(basePoint[0], basePoint[1], basePoint[0] - 27.24583, basePoint[1] + 29.141318),
  //       // bounds: window.Microsoft.Maps.LocationRect.fromEdges(32.4, 71.8, 10.15, 93.95),
  //       bounds: window.Microsoft.Maps.LocationRect.fromEdges(30.45, 79.65, 30.30, 79.80),
  //       // imageUrl: `/treeimages/output_MCD12Q1.006_LC_Type1_doy${currentDate}_aid0002.png`,
  //       // imageUrl: `/treeimages/india.png`,
  //       // imageUrl: `/treeimages/output_MCD12Q1.006_LC_Type1_doy2001001_aid0001.png`,
  //       imageUrl:`/demImage/FilteredDSM_reg_filled1.png`,
  //       opacity: 0.8
  //     });
  //     itMap.layers.insert(overlayRef.current);

  //     // for(let i = 0 ; i < 180; i ++) {
  //     //   itMap.entities.push(new window.Microsoft.Maps.Polygon([
  //     //       new window.Microsoft.Maps.Location(i - 90, 0),
  //     //       new window.Microsoft.Maps.Location(i - 89.99, 0),
  //     //       new window.Microsoft.Maps.Location(i - 89.99, 180),
  //     //       new window.Microsoft.Maps.Location(i - 90, 180),
  //     //     ],
  //     //     { fillColor: `rgba(255, 0, 0, 0.4)`, strokeColor: `rgba(255, 0, 0, 0)`, strokeWidth: 10 }
  //     //   ));
  //     // }

  //   } catch (e) {
  //     console.log(e);
  //     setMap(null);
  //   }
  // }
  const extent = [79.65, 79.80,30.30, 30.45]
  // 解析西、东、南、北参数
  const west = extent[0], east = extent[1], south = extent[2], north = extent[3]
    // 获取primitive
  const getPrimitive = () =>{
    return new Cesium.Primitive({
      geometryInstances: new Cesium.GeometryInstance({
        geometry: new Cesium.RectangleGeometry({
          rectangle: Cesium.Rectangle.fromDegrees(
            west,
            south,
            east,
            north
          ),
          vertexFormat: Cesium.EllipsoidSurfaceAppearance.VERTEX_FORMAT,
        }),
      }),
      appearance: new Cesium.EllipsoidSurfaceAppearance({
        material: imageMaterial.current,
        aboveGround: false,
      }),
    })
  }
  const nextTime = () => {
    try{
        
        // 纬度移动幅度、经度移动幅度、纬度缩放比例、经度缩放比例
        let latDelta = 0.001, lonDelta = 0.001, latScale = 0.01, lonScale = 0.01;//(https://img-blog.csdnimg.cn/direct/5b604d9cb241470781800c153e7a9434.png)
        // 创建一个静态图片材质
        imageMaterial.current = new Cesium.Material({
          fabric: {
            type: 'Image',
            uniforms: {
              image:picUrl.current  
            },
          }
        });
      
        // 添加primitive
        staticImagePrimitive.current = cesMap.current.scene.primitives.add(
          getPrimitive()
        );
        // flyTo 添加的 primitive
        cesMap.current.camera.flyTo({
          destination : Cesium.Rectangle.fromDegrees(west, south, east, north)
        });


      

    } catch (e) {

      setMap(null);
    }
  }

  const handleModeChange = (mode) =>{
    // console.log('mode', mode)
    if(mode!== currentMode.current){
      console.log(23333)
      currentMode.current = mode
      cesMap.current.camera.flyTo({
        destination : Cesium.Rectangle.fromDegrees(west, south, east, north),
   

      });

    }
    
  }

  useEffect(() => {
    try {
      window.GetMap = nextTime;
    } catch (e) {
    }
    nextTime();
  }, []);

  const updateMap = (date) => {
    // try{
    //   if(map) {
    //     overlayRef.current.setOptions({
    //       imageUrl: `/treeimages/output_MCD12Q1.006_LC_Type1_doy${labelList[currentDateIndex]}001_aid0001.png`,
    //       opacity: 0.6
    //     });
    //   }
    // } catch (e) {
    // }
  };

  useEffect(async () => {
    if(lastCurrentDateIndex.current !== currentDateIndex) {
      lastCurrentDateIndex.current = currentDateIndex;
      updateMap(dateList[currentDateIndex]);
    }
  }, [currentDateIndex, lastCurrentDateIndex.current]);

  const handleCtrlChange = (index) => {
    setCurrentDate(index);
  };

  const hangeleChangePic = (item)=>{
    setChangePic(item)
    console.log(item)
   let  url = item?`/demImage/FilteredDSM_filled1.png`:`/demImage/FilteredDSM_reg_filled1.png`;
   picUrl.current = url
   imageMaterial.current.uniforms.image = picUrl.current;
   cesMap.current.scene.primitives.remove(staticImagePrimitive.current);
   staticImagePrimitive.current = cesMap.current.scene.primitives.add(getPrimitive());

  // console.log(picUrl.current)
  //  console.log( imageMaterial.current)
    try{
      if(map) {
        overlayRef.current.setOptions({
          imageUrl: url,
          opacity: 0.8
        });
      }
    } catch (e) {
    }
  }


  return (
    <React.Fragment>
      <Helmet title="Planet DEM Planet Digital Elevation Model" />
      {/* <div ref={mapRef} className={classes.root} /> */}
      <div className={classes.root}>
        <CesiumMap
          ref={cesMap} modeChange={handleModeChange}

        />
      </div>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 300,
          left: '10px',
          bottom: '90px',
          whiteSpace: 'nowrap'
        }}
      >
        <a
          href={changePic ? `/demImage/FilteredDSM_filled1.png`:`/demImage/FilteredDSM_reg_filled1.png`}
          download={`${changePic ? 'after' : 'before'}.png`}
          style={{
            display: 'block',
            textDecoration: 'none',
            fontSize: '14px',
            padding: '7px 15px',
            border: '1px solid #ccc',
            borderRadius: '3px',
            color: '#fff',
            backgroundColor: 'rgba(25, 118, 210, 1)'
          }}
        >
          DOWNLOAD DATA
        </a>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 300,
          right: '10px',
          bottom: '55px',
          whiteSpace: 'nowrap'
        }}
      >
        <img
          alt=""
          style={{width: '260px'}}
          src={require('../../static/images/legend@3x.png')}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 300,
          right: '10px',
          bottom: '105px',
          fontSize: '16px',
          borderRadius: '40px',
          background: 'blue',
          color: '#fff',
          padding: '7px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        Unit: m
      </Box>
      <MapCtrol
        datas={
          dateList1
        }
        labels={
          dateList1.map((item) => {
            return item
          })
        }
        isLoading={isLoading}
        currentDateIndex={changePic}
        handleCtrlChange={hangeleChangePic}
        noTip
      />
    </React.Fragment>
  );
}

export default App;
