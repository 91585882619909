import React, {useEffect, useRef, useState} from 'react'
import Box from "@mui/material/Box/Box";
import Paper from "@mui/material/Paper/Paper";
import Stack from "@mui/material/Stack/Stack";
import IconButton from "@mui/material/IconButton/IconButton";
import {PauseCircleFilled, PauseCircle, Pause, PlayCircleFilled, SkipNext, PlayCircle, PlayArrow} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import Button from "@mui/material/Button/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from 'moment'
import { styled } from '@mui/material/styles';
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Typography from "@mui/material/Typography/Typography";
// import 'moment/locale/zh-cn'
// moment.locale('zh-cn');

let timer = null;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default (props) => {

  const {timeLineStartPause, labels, currentDateIndex = 0, datas, handleCtrlChange, isLoading, noTip } = props;

  const [playState, setPlayState] = useState(false);
  const playStateRef = useRef();

  const timeRef = useRef();

  const lastDateIndex = useRef(0);

  let newLabels = [];
  // if(labels && labels.length > 8) {
  //   newLabels = [
  //     labels[0],
  //     labels[parseInt(labels.length / 4)],
  //     labels[parseInt(labels.length / 2)],
  //     labels[parseInt(labels.length / 4 * 3)],
  //     labels[labels.length - 1],
  //   ]
  // } else {
  //   newLabels = labels || [];
  // }

  // labels.map((item) => {
  //   newLabels.push(item.slice(8))
  // });

  const handleClick = (index) => () => {
    if(handleCtrlChange) {
      handleCtrlChange(index);
    }
  };

  function playAction () {

    clearTimeout(timeRef.current);

    if(playStateRef.current) {

      handleCtrlChange((currentDateIndex) => {

        const next = currentDateIndex + 1;
        return currentDateIndex >= labels.length - 1 ? 0 : next;
      });

      timeRef.current = setTimeout(playAction, 3000);
    }
  };

  useEffect(() => {
    if(playStateRef.current !== playState) {
      playStateRef.current = playState;
      if(playState) {
        playAction();
      }
    }
  }, [playState, currentDateIndex, playStateRef]);

  const handlePlayClick = () => {
    // const next = currentDateIndex + 1
    // handleCtrlChange(currentDateIndex + 1 >= labels.length - 1 ? 0 : next);

    if(playState) {
      setPlayState(false);
    } else {
      setPlayState(true);
    }

  };

  return (
    <Stack
      sx={{
        position: 'absolute',
        // backgroundColor: '#fff',
        bottom: 15,
        height: '50px',
        zIndex: 999,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}
    >
      <Box
        sx={{
          ml: 1,
          mr: 1,
          background: '#fff',
          borderRadius: '50%',
        }}
      >
        <IconButton
          color={"primary"}
          onClick={handlePlayClick}
        >
          {
            playState ?
              <Pause /> :
              <PlayArrow />
          }
        </IconButton>
      </Box>
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          ml: 1,
          mr: 2,
        }}
      >
        <LinearProgress
          sx={{
            background: '#fff',
            boxShadow: '0px 0px 3px #ccc'
          }}
          variant="determinate"
          value={currentDateIndex === labels.length - 1 ? 100 : ((currentDateIndex + 0.5)  / labels.length) * 100}
        />
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            // left: '-16px',
            // right: '-16px',
            left: 0,
            right: 0,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {
            labels.map((item, index) => {
              return (
                <Box
                  key={'map-ct-' + index}
                  sx={{
                    flex: 1,
                    color: '#fff',
                    whiteSpace: 'no-wrap',
                    borderLeft: '2px solid #fff',
                    boxShadow: '-1px -2px 2px #ccc',
                    textAlign: 'left',
                    textShadow: '0px 0px 4px #333',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    paddingLeft: '5px',
                    // borderRight: index === newLabels.length - 1 ? '2px solid #fff' : 'none',
                  }}
                >
                  {
                    item
                  }
                </Box>
              )
            })
          }
        </Box>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'stretch',
            zIndex: 999,
            left: 0,
            right: 0,
            top: '-10px',
            bottom: '-10px',
          }}
        >
          {
            datas.map((item, index) => {
              // console.log(item);
              return (
                <Box
                  key={'map-tr-on-' + index}
                  sx={{
                    flex: 1,
                    // border: '1px solid #f00',
                    height: '30px',
                  }}
                >
                  {noTip ? (
                    <div
                      onClick={handleClick(index)}
                      style={{height: '100%'}}
                    />
                  ) : (
                    <HtmlTooltip
                      title={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: '2px'
                          }}
                        >
                          {
                            isLoading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Typography
                                variant="body2"
                              >
                                {
                                  moment(item, 'YYYY-MM-DD').format('YYYY ddd MM-DD')
                                }
                              </Typography>
                            )
                          }
                        </Box>
                      }
                      arrow
                      placement="top"
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      TransitionProps={{
                        timeout: 0
                      }}
                      open={index === currentDateIndex}
                    >
                      <div
                        onClick={handleClick(index)}
                        style={{height: '100%'}}
                      />
                    </HtmlTooltip>
                  )}

                </Box>
              )
            })
          }
        </Box>
      </Box>
    </Stack>
  )
}
