/**
 * Created by luzq on 2017/9/4.
 */
import * as actionTypes from '../constants/ActionType'
import { Map, fromJS } from 'immutable'

// const baseInit = fromJS({
//   state: 0,
//   lastFetched: null,
//   isLoading: false,
//   error: null,
//   data: null,
//   user: null,
//   needPull: false
// });
//
// const ListInit = fromJS({
//   state: 0,
//   param: null,
//   lastFetched: null,
//   isLoading: false,
//   error: null,
//   data: null,
//   list: null,
//   totalPage: 1,
//   totalNumber: 0,
//   currentPage: 1,
//   countPerPage: 10,
//   cache: null,
//   needPull: false
// });

/*
* radar Time list
*
* */

/*
* code
* */
export function authCode(state = fromJS({}), action) {
  switch (action.type) {
    case actionTypes.UPDATE_CODE:
      return state.merge({
        ...action.param
      })
    default:
      return state;
  }
}

/* 弹窗 */
const modalInit = Map({
  data: null,
  status: null
});

export function modal(state = modalInit, action) {
  switch (action.type) {
    case actionTypes.SET_MODAL:
      return state.merge({
        data: action.param.data ? action.param.data : null,
        status: 1
      })
    case actionTypes.CLOSE_MODAL:
      return state.merge({
        data: action.param.data ? action.param.data : {},
        status: 0
      })
    case actionTypes.WAITING_MODAL:
      return state.merge({
        status: 2
      })
    case actionTypes.SET_MODAL_STATUS:
      return state.merge({
        status: action.param ? action.param : 0
      })
    default:
      return state;
  }
}
