import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';


// import { createBrowserHistory } from 'history'
import configureStore from './redux/store'
import Root from './layouts/Root'

const preloadedState = window.__PRELOADED_STATE__;
// Create a history of your choosing (we're using a browser history in this case)
const store = configureStore(preloadedState);

ReactDOM.render(
  <React.Fragment>
    <Root store={store} />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
