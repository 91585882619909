export default {
  grace: {
    txt: `The GRACE/GRACE-FO level-2 (L2) spherical harmonic (SH) models are obtained using the improved energy balance approach. 11-day level-1b data are collected to produce one SH model and the 11-day window slides with daily step to generate daily-updated SH models. Standard post-processing procedures, e.g., replacing low-degree SH coefficients, decorrelation filtering, 350-km Gaussian smoothing, GIA correction, etc, are adopted to produce terrestrial water storage anomaly (TWSA) from the SH models. TWSA with respect to the annual mean of 2013 in terms of equivalent water height (EWH) are shown in the portal.
References:
Shang, K., Guo, J., Shum, C.K., Dai, C. and Luo, J., 2015. GRACE time-variable gravity field recovery using an improved energy balance approach. Geophysical Supplements to the Monthly Notices of the Royal Astronomical Society, 203(3), pp.1773-1786.
Guo, J.Y., Shang, K., Jekeli, C. and Shum, C.K., 2015. On the energy integral formulation of gravitational potential differences from satellite-to-satellite tracking. Celestial Mechanics and Dynamical Astronomy, 121, pp.415-429.
`
  },
  ewhDeep: {
    txt: `Uz, M., Atman, K.G., Akyilmaz, O., Shum, C.K., Keleş, M., Ay, T., Tandoğdu, B., Zhang, Y. and Mercan, H., 2022. Bridging the gap between GRACE and GRACE-FO missions with deep learning aided water storage simulations. Science of The Total Environment, 830, p.154701.
Agarwal, V., Akyilmaz, O., Shum, C.K., Feng, W., Yang, T.Y., Forootan, E., Syed, T.H., Haritashya, U.K. and Uz, M., 2023. Machine learning based downscaling of GRACE-estimated groundwater in Central Valley, California. Science of The Total Environment, 865, p.161138.
`
  },
  classification: {
    txt: `The DL-aided land use land cover (LULC) classification data product is derived from the Norway’s International Climate and Forests Initiative (NICFI) high-resolution (4.77m per pixel) basemaps. The deep learning model we used is the combination of HRNetV2 backbone [1] and a FCN [2] segmentation head. The model is trained using the framework from a public library: MMSegmentation [3]. We are using the Finer Resolution Observation and Monitoring – Global Land Cover (FROM-GLC10) [4] as reference data. The training dataset composed of more than 4500 patches of 4.77m resolution Planet satellite images in India and the corresponding 10m resolution FROM-GLC10 labels of ten categories: cropland, forest, grassland, shrubland, wetland, water, tundra, impervious surface, barren land, snow/ice.
References:
[1]	K. Sun et al., “High-resolution representations for labeling pixels and regions,” ArXiv Prepr. ArXiv190404514, 2019.
[2]	J. Long, E. Shelhamer, and T. Darrell, “Fully convolutional networks for semantic segmentation,” in Proceedings of the IEEE conference on computer vision and pattern recognition, 2015, pp. 3431–3440.
[3]	MMSegmentation Contributors, OpenMMLab Semantic Segmentation Toolbox and Benchmark. 2020. Accessed: Apr. 27, 2022. [Online]. Available: https://github.com/open-mmlab/mmsegmentation
[4]	B. Chen et al., “Stable classification with limited sample: Transferring a 30-m resolution sample set collected in 2015 to mapping 10-m resolution global land cover in 2017,” Sci Bull, vol. 64, pp. 370–373, 2019.
`
  },
  igbp: {
    txt: `The Terra and Aqua combined Moderate Resolution Imaging Spectroradiometer (MODIS) Land Cover Type (MCD12Q1) Version 6.1 data product provides global land cover types at yearly intervals (2001-2022). The MCD12Q1 data product is derived using supervised classifications of MODIS Terra and Aqua reflectance data. Land cover types are derived from the International Geosphere-Biosphere Programme (IGBP) classification schemes.
Citation: Friedl, M., D. Sulla-Menashe. MODIS/Terra+Aqua Land Cover Type Yearly L3 Global 500m SIN Grid V061. 2022, distributed by NASA EOSDIS Land Processes DAAC, https://doi.org/10.5067/MODIS/MCD12Q1.061.
`
  },
  water: {
    txt: `Inland water level monitoring system over India for the last three decades (1991 to present) from multi-mission satellite radar altimetry.
Multi-source GIS datasets, including Global River Widths from Landsat (GRWL) mask and vector products, Global Runoff Data Centre (GRDC) Major River Basins of the World (MRB), HydroLAKES and Global Reservoir and Dam (GRanD), are used to identify locations where altimeter ground tracks cross water bodies. These locations are potential virtual stations (VSs) for altimetry data extraction.
For radar altimetry data, media corrections (dry and wet troposphere corrections, and the ionosphere correction based on global ionosphere maps), and geophysical corrections (solid Earth and pole tides) are applied, and sea state bias correction is not included. Then the geoid Earth Gravitational Model 2008 (EGM2008) was utilized as vertical datum.
`
  },
  gfch: {
    txt: `The 30-m spatial resolution global forest canopy height map was developed through the integration of the Global Ecosystem Dynamics Investigation (GEDI) lidar forest structure measurements and Landsat analysis-ready data time-series. 
Citation: P. Potapov, X. Li, A. Hernandez-Serna, A. Tyukavina, M.C. Hansen, A. Kommareddy, A. Pickens, S. Turubanova, H. Tang, C.E. Silva, J. Armston, R. Dubayah, J. B. Blair, M. Hofton (2020) Mapping and monitoring global forest canopy height through integration of GEDI and Landsat data. Remote Sensing of Environment, 112165  https://doi.org/10.1016/j.rse.2020.112165
`
  },
  bionmass: {
    txt: `This Global Ecosystem Dynamics Investigation (GEDI) L4B product provides 1 km x 1 km (1 km, hereafter) estimates of mean aboveground biomass density (AGBD) based on observations from mission week 19 starting on 2019-04-18 to mission week 138 ending on 2021-08-04.
Citation: Dubayah, R.O., J. Armston, S.P. Healey, Z. Yang, P.L. Patterson, S. Saarela, G. Stahl, L. Duncanson, and J.R. Kellner. 2022. GEDI L4B Gridded Aboveground Biomass Density, Version 2. ORNL DAAC, Oak Ridge, Tennessee, USA. https://doi.org/10.3334/ORNLDAAC/2017
`
  },
  flood: {
    txt: `The Global Flood Database contains maps of the extent and temporal distribution of  flood events occurring between 2000-2018. 
The flood maps in the Global Flood Database were created using NASA's MODIS (Aqua (MYD09GA/GQ) and Terra (MOD09GA/GQ) satellites. The flood events mapped in this database represent major events recorded by the DFO Flood Observatory since the beginning of the satellite records. 
Citation: Tellman, B., Sullivan, J.A., Kuhn, C. et al. Satellite imaging reveals increased proportion of population exposed to floods. Nature 596, 80–86 (2021). https://doi.org/10.1038/s41586-021-03695-w
`
  },
  fire: {
    txt: `The Fire Information for Resource Management System (FIRMS) distributes Near Real-Time (NRT) active fire data from the Moderate Resolution Imaging Spectroradiometer (MODIS) aboard the Aqua and Terra satellites, and the Visible Infrared Imaging Radiometer Suite (VIIRS) aboard S-NPP and NOAA 20 (formally known as JPSS-1).
Citations:
NRT VIIRS 375 m Active Fire product VJ114IMGTDL_NRT distributed from NASA FIRMS. doi:10.5067/FIRMS/VIIRS/VJ114IMGT_NRT.002
NRT VIIRS 375 m Active Fire product VNP14IMGT distributed from NASA FIRMS. doi:10.5067/FIRMS/VIIRS/VNP14IMGT_NRT.002
MODIS Collection 61 NRT Hotspot / Active Fire Detections MCD14DL distributed from NASA FIRMS doi:10.5067/FIRMS/MODIS/MCD14DL.NRT.0061
MODIS Collection 6 Hotspot / Active Fire Detections MCD14ML distributed from NASA FIRMS. doi:10.5067/FIRMS/MODIS/MCD14ML
`
  }
}