import React, { Component } from 'react'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import AppLayout from './AppLayout'
import ErrorBoundary from './ErrorBoundary'
import { ThemeProvider, createTheme } from '@mui/material/styles'
// import { green, orange } from '@material-ui/core/colors'

const theme = createTheme({});

export default class Root extends Component {
  render() {
    const { store } = this.props
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            {/*<ConnectedRouter history={history}>*/}
              <AppLayout />
            {/*</ConnectedRouter>*/}
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    )
  }
}


Root.propTypes = {
  store: PropTypes.object.isRequired,
  // history: PropTypes.object.isRequired
}
