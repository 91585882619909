import defaultConfig from './dev'

const config = {
  DEV: false,
  API_VERSION :process.env.REACT_APP_API_VERSION || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '1.0.0',
  FILE_ROOT: process.env.REACT_APP_API_ROOT || '',
  APP_BASE_URL: process.env.REACT_APP_BASE_URL || '/',
  jsonUrl: '/jsondata/',
}

export default { ...defaultConfig, ...config }
